import React, { useState, useEffect } from "react"
import { SITE_SCOPES, WP_API_URL } from "../../constants"
import { getCurrentLanguage } from "../../helper"
import { Layout } from "../index"
import strings from "./CheckoutBuilder.strings"
import CheckoutTop from "../../components/CheckoutTop/CheckoutTop"
import CheckoutPayment from "../../components/CheckoutPayment/CheckoutPayment"
import { getCookie } from "../../helper"
import CheckoutConfirmation from "../../components/CheckoutConfirmation/CheckoutConfirmation"
import Helmet from "react-helmet"
import { resultTransaction } from "../../components/CheckoutPayment/CheckoutPayment.actions"

export default function CheckoutBuilder({
  location,
  pageContext,
  siteScope = SITE_SCOPES.main,
}) {
  const currentLang = getCurrentLanguage(location)
  const [product, setProduct] = useState(null)
  const [skew, setSkew] = useState((9.5 / 180) * Math.PI)
  const [isError, setError] = useState(location.pathname.includes("error"))
  const [isSuccess, setIsSuccess] = useState(
    location.pathname.includes("success")
  )
  const [cart, setCart] = useState(undefined)
  useEffect(() => {
    const success = location.pathname.includes("success")
    const error = location.pathname.includes("error")
    const transactionID = new URLSearchParams(location.search).get(
      "datatransTrxId"
    )
    const back = () => {
      window.history.replaceState(
        {},
        document.title,
        location.href.replace("/success", "")
      )
      setIsSuccess(false)
    }
    if (success && !transactionID) {
      back()
    } else if (success && transactionID) {
      resultTransaction(transactionID, "success")
        .then(() => {
          localStorage.removeItem("rivella-checkout")
          document.cookie = `rivella_cart=;expires=${new Date().toUTCString()};path=/`
        })
        .catch(() => {
          window.history.replaceState(
            {},
            document.title,
            location.href.replace("/success", "/error")
          )
          setIsSuccess(false)
          setError(true)
        })
    } else if (error && !transactionID) {
      window.history.replaceState(
        {},
        document.title,
        location.href.replace("/error", "")
      )
    } else if (error && transactionID) {
      resultTransaction(transactionID, "error").catch(back)
    }
  }, [])
  useEffect(() => {
    function handle() {
      const deg =
        window.innerWidth >= 1100
          ? Math.atan2(335 - 120, window.innerWidth)
          : (9.5 / 180) * Math.PI
      setSkew(-deg)
    }
    window.addEventListener("resize", handle)
    handle()
    return () => window.removeEventListener("resize", handle)
  }, [])
  useEffect(() => {
    // development functions
    window.setCookies = (id = 5355) => {
      const d = new Date()
      d.setTime(d.getTime() + 24 * 60 * 60 * 1000)
      const expires = "expires=" + d.toUTCString()
      document.cookie = `rivella_cart={"cart":[{"product":${id},"quantity":2}]};${expires};path=/`
      document.location.reload()
    }
    window.removeCookies = () => {
      document.cookie = `rivella_cart=;expires=${new Date().toUTCString()};path=/`
      document.location.reload()
    }
    // TODO: check for cookie
    let rivellaCart = getCookie("rivella_cart")
    rivellaCart = rivellaCart ? JSON.parse(rivellaCart)?.cart[0] : null
    setCart(rivellaCart)
    if (rivellaCart) {
      fetch(
        `https://${WP_API_URL}/wp-json/rivella-checkout/v1/get_product?product=${rivellaCart?.product}`
      )
        .then((res) => res.json())
        .then((res) => {
          if (res.result === "success") {
            setProduct({ ...res, product_id: rivellaCart?.product })
          } else {
            document.cookie = `rivella_cart=;expires=${new Date().toUTCString()};path=/`
            setCart(null)
          }
        })
    }
  }, [])
  const { allEvents = [], allProducts = [], allPages = [] } = pageContext
  const pageData = strings[currentLang.slug]
  const languageOptions = {
    currentLang,
    options: [],
  }
  if (!pageData) return null
  if (cart === undefined) return null
  return (
    <Layout
      pageName={pageData.title}
      headerIsTransparent
      location={location}
      languageOptions={languageOptions}
      allProducts={allProducts}
      allEvents={allEvents}
      allPages={allPages}
      siteScope={siteScope}
    >
      <Helmet>
        <script src="https://pay.sandbox.datatrans.com/upp/payment/js/datatrans-2.0.0.js" />
      </Helmet>
      <div
        style={{
          "--skew": skew + "rad",
          "--offset": Math.tan(-skew) * 100 * 1.3 + "%",
        }}
      >
        <CheckoutTop
          title={pageData.title}
          subtitle={pageData.subtitle}
          isError={isError}
        />
        {isSuccess ? (
          <CheckoutConfirmation
            type={"confirm"}
            pageData={pageData}
            location={location}
          />
        ) : cart ? (
          <CheckoutPayment
            qty={cart?.quantity}
            setError={setError}
            isError={isError}
            product={product}
            pageData={pageData}
            skew={skew}
          />
        ) : (
          <CheckoutConfirmation
            type={"error"}
            pageData={pageData}
            location={location}
          />
        )}
      </div>
    </Layout>
  )
}
