import { WP_API_URL } from "../../constants"

const networkMiddleware = (res) => {
  if (res.status !== 200) throw new Error("request failed")
  return res.json()
}
const CHECKOUT_URL = `https://${WP_API_URL}/wp-json/rivella-checkout/v1`
export const createTransaction = (params) => {
  return fetch(
    `${CHECKOUT_URL}/create_transaction?${new URLSearchParams(
      params
    ).toString()}`,
    {
      method: "post",
    }
  ).then(networkMiddleware)
}
export const updateTransaction = (params) => {
  return fetch(
    `${CHECKOUT_URL}/update_transaction?${new URLSearchParams(
      params
    ).toString()}`,
    {
      method: "post",
    }
  ).then(networkMiddleware)
}
export const resultTransaction = (transaction_id, frontend_result) => {
  return fetch(
    `${CHECKOUT_URL}/result_transaction?${new URLSearchParams({
      transaction_id,
      frontend_result,
    }).toString()}`,
    {
      method: "post",
    }
  ).then(networkMiddleware)
}
export const getStatus = (transaction_id) => {
  return fetch(
    `${CHECKOUT_URL}/get_status?transaction_id=${transaction_id}`
  ).then(networkMiddleware)
}
