const strings = {
  de: {
    title: "Checkout",
    subtitle: "please proceed to purchase tickets for our next event",
    form_title: "More about you...",
    first_name: "Your First Name",
    last_name: "Your Last Name",
    email: "Your Email Address",
    street: "Street",
    no: "No",
    zip: "PLZ",
    city: "Ort",
    country: "Country",
    subscribe: "I want to get a newsletter",
    subtotal: "Subtotal",
    tax: "Tax",
    total: "Total",
    currency: "CHF",
    how_to_pay: "How would you like to pay?",
    credit_card: "Credit Card",
    proceed_payment: "Proceed With Payment",
    error: "There was an error, please try again",
    thanks_title: "Thank you for your payment",
    thanks_subtitle: "We emailed you a receipt",
    no_checkout_title: "Ooops, There's nothing to pay.",
    no_checkout_subtitle: "Navigate our page ad try again",
    go_back: "Go Back to Website",
  },
  fr: {
    title: "Checkout",
    subtitle: "please proceed to purchase tickets for our next event",
    form_title: "More about you...",
    first_name: "Your First Name",
    last_name: "Your Last Name",
    email: "Your Email Address",
    street: "Street",
    no: "No",
    zip: "PLZ",
    city: "Ort",
    country: "Country",
    subscribe: "I want to get a newsletter",
    subtotal: "Subtotal",
    tax: "Tax",
    total: "Total",
    currency: "CHF",
    how_to_pay: "How would you like to pay?",
    credit_card: "Credit Card",
    proceed_payment: "Proceed With Payment",
    error: "There was an error, please try again",
    thanks_title: "Thank you for your payment",
    thanks_subtitle: "We emailed you a receipt",
    no_checkout_title: "Ooops, There's nothing to pay.",
    no_checkout_subtitle: "Navigate our page ad try again",
    go_back: "Go Back to Website",
  },
  it: {
    title: "Checkout",
    subtitle: "please proceed to purchase tickets for our next event",
    form_title: "More about you...",
    first_name: "Your First Name",
    last_name: "Your Last Name",
    email: "Your Email Address",
    street: "Street",
    no: "No",
    zip: "PLZ",
    city: "Ort",
    country: "Country",
    subscribe: "I want to get a newsletter",
    subtotal: "Subtotal",
    tax: "Tax",
    total: "Total",
    currency: "CHF",
    how_to_pay: "How would you like to pay?",
    credit_card: "Credit Card",
    proceed_payment: "Proceed With Payment",
    error: "There was an error, please try again",
    thanks_title: "Thank you for your payment",
    thanks_subtitle: "We emailed you a receipt",
    no_checkout_title: "Ooops, There's nothing to pay.",
    no_checkout_subtitle: "Navigate our page ad try again",
    go_back: "Go Back to Website",
  },
}

export default strings
