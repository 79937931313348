import React from "react"
import s from "./CheckoutTop.module.scss"

const CheckoutTop = ({ title, subtitle, isError }) => {
  return (
    <div className={s.Wrapper}>
      <div className={s.Stripe}>
        <div className={s.Skewed}>
          <h1 className={s.Title}>{title}</h1>
        </div>
      </div>
      <div className={s.Skewed}>
        {isError ? null : <p className={s.Subtitle}>{subtitle}</p>}
      </div>
    </div>
  )
}

export default CheckoutTop
