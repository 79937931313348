import React from "react"
import s from "./CheckoutConfirmation.module.scss"
import { getCurrentLanguage } from "../../helper"
import { Link } from "gatsby"

const ErrorIcon = () => (
  <svg viewBox="0 0 352 344" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M79.3941 207.153L93.8302 192.717C97.7355 188.812 97.7355 182.48 93.8302 178.575L52.6369 137.382C43.8385 128.583 41.145 115.1 47.7446 104.551C74.2534 62.1809 121.336 34 175 34C179.578 34 184.109 34.2051 188.583 34.6067C198.201 35.4699 204.41 44.2455 207.227 53.482C212.974 72.3294 229.466 83 248.5 83C252.802 83 256.957 82.3756 260.882 81.2125C270.406 78.3898 281.624 78.0313 288.149 85.5216C311.101 111.871 325 146.312 325 184C325 266.843 257.843 334 175 334C122.298 334 75.9446 306.821 49.1909 265.715C42.3095 255.142 44.9362 241.42 53.8565 232.5L79.2987 207.058L79.3941 207.153ZM26.29 203.76C27.0116 209.244 33.6279 211.22 37.5388 207.309L51.4734 193.375C55.3787 189.469 55.3787 183.138 51.4734 179.233L37.0388 164.798C33.0694 160.829 26.362 162.933 25.7893 168.517C25.2674 173.607 25 178.772 25 184C25 190.698 25.4391 197.294 26.29 203.76Z"
      fill="#F8F8F8"
    />
    <circle cx="250.5" cy="34.5" r="34.5" fill="#F8F8F8" />
    <path d="M281 38L352 47L80 315.194L14.5 307.5L281 38Z" fill="#D4E0EC" />
    <rect
      width="55"
      height="255"
      transform="matrix(0.707107 -0.707107 -0.707107 -0.707107 246.211 295.102)"
      fill="#D4E0EC"
    />
  </svg>
)
const ConfirmIcon = () => (
  <svg viewBox="0 0 350 344" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M288.149 85.5216C281.624 78.0313 270.406 78.3898 260.882 81.2125C256.957 82.3756 252.802 83 248.5 83C229.466 83 212.974 72.3294 207.227 53.482C204.41 44.2455 198.201 35.4699 188.583 34.6067C184.109 34.2051 179.578 34 175 34C92.1573 34 25 101.157 25 184C25 266.843 92.1573 334 175 334C257.843 334 325 266.843 325 184C325 146.312 311.101 111.871 288.149 85.5216Z"
      fill="#F8F8F8"
    />
    <circle cx="250.5" cy="34.5" r="34.5" fill="#F8F8F8" />
    <g clipPath="url(#clip0)">
      <path
        d="M321.137 128.5C320.424 124.614 318.858 121.099 316.668 118.03C300.16 86.336 251.628 97.6086 181.672 108.548C169.954 110.396 174.149 95.4731 172.823 64.6807C171.944 44.628 153.904 16.9262 132.592 20.7381C97.4874 27.0472 136.23 48.5357 117.501 122.282C107.475 161.663 66.7382 147.957 73.344 184.522L88.3577 267.797C90.9439 282.027 94.814 295.464 115.31 294.069C135.18 292.741 133.3 307.376 161.415 302.304L302.229 276.926C316.552 274.351 326.159 260.611 323.578 246.334C322.496 240.409 319.453 235.384 315.302 231.648C322.476 225.78 326.418 216.43 324.658 206.717C323.576 200.792 320.527 195.813 316.428 192.037C323.602 186.168 327.591 176.824 325.837 167.065C324.559 160.023 320.53 154.118 315.063 150.27C320.039 144.513 322.618 136.612 321.137 128.5Z"
        fill="#D4E0EC"
      />
      <path
        opacity="0.2"
        d="M232.729 171.192L299.835 159.098C308.963 157.456 316.633 151.031 319.922 142.361C321.262 138.773 319.42 134.797 315.831 133.457C312.243 132.117 308.267 133.959 306.927 137.547C305.378 141.68 301.733 144.702 297.408 145.505L228.702 157.879C221.905 159.092 215.378 154.592 214.118 147.789C212.905 140.993 217.446 134.518 224.248 133.258L270.181 124.993C273.968 124.315 276.448 120.723 275.765 116.983C275.081 113.243 271.489 110.763 267.708 111.394L221.775 119.66C207.452 122.234 197.846 135.975 200.426 150.251C201.877 158.216 206.848 164.569 213.373 168.309C209.419 173.858 207.605 180.95 208.913 188.138C210.363 196.103 215.376 202.508 221.947 206.254C218.041 211.809 216.232 218.854 217.54 226.042C219.092 234.732 224.854 241.608 232.308 245.129C229.05 250.43 227.555 256.849 228.744 263.453C231.324 277.729 245.076 287.242 259.399 284.667L302.229 276.926C311.357 275.285 319.074 268.865 322.31 260.237C323.65 256.648 321.855 252.678 318.267 251.338C314.678 249.998 310.655 251.834 309.321 255.376C307.772 259.508 304.127 262.531 299.756 263.327L256.979 271.027C250.182 272.241 243.655 267.741 242.395 260.938C241.182 254.142 245.723 247.667 252.525 246.407L303.268 237.256C312.396 235.615 320.112 229.195 323.355 220.52C324.695 216.932 322.899 212.961 319.311 211.622C315.676 210.276 311.7 212.118 310.366 215.659C308.811 219.839 305.218 222.82 300.8 223.611L245.769 233.663C238.972 234.877 232.445 230.376 231.185 223.574C229.972 216.778 234.513 210.303 241.315 209.043L304.441 197.651C313.569 196.01 321.286 189.59 324.522 180.962C325.862 177.373 324.067 173.403 320.479 172.063C316.89 170.723 312.867 172.559 311.533 176.101C309.979 180.28 306.386 183.261 301.968 184.052L237.142 195.76C230.345 196.973 223.818 192.473 222.558 185.67C221.339 178.921 225.886 172.399 232.729 171.192Z"
        fill="#66809A"
      />
      <path
        d="M113.061 300.906L69.2145 308.808C63.1428 309.92 57.3187 305.885 56.2539 299.819L32.7729 169.542C31.6613 163.47 35.6958 157.646 41.7618 156.581L85.6085 148.679C91.6802 147.567 96.5 151 98.5691 157.668L135 247L106 272L122.056 287.899C123.167 293.97 119.133 299.794 113.061 300.906Z"
        fill="white"
      />
      <path
        d="M97.0611 303.906L53.2145 311.808C47.1428 312.92 41.3187 308.885 40.2539 302.819L16.7729 172.542C15.6613 166.47 19.6958 160.646 25.7618 159.581L69.6085 151.679C75.6802 150.567 80.5 154 82.5691 160.668L119 250L90 275L106.056 290.899C107.167 296.97 103.133 302.794 97.0611 303.906Z"
        fill="#66809A"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="315.132"
          height="300"
          fill="white"
          transform="translate(36.3169 8) rotate(6.95306)"
        />
      </clipPath>
    </defs>
  </svg>
)
const CheckoutConfirmation = ({ type, pageData, location }) => {
  const currentLang = getCurrentLanguage(location)
  return (
    <div className={s.Container}>
      {type === "confirm" ? (
        <>
          <ConfirmIcon />
          <h1>{pageData.thanks_title}</h1>
          <h2>{pageData.thanks_subtitle}</h2>
        </>
      ) : (
        <>
          <ErrorIcon />
          <h1>{pageData.no_checkout_title}</h1>
          <h2>{pageData.no_checkout_subtitle}</h2>
          <Link to={`${location.origin}/${currentLang.slug}/`}>
            {pageData.go_back}
          </Link>
        </>
      )}
    </div>
  )
}

export default CheckoutConfirmation
